@tailwind base;
@tailwind components;
@tailwind utilities;

.rdp-cell button {
    margin: 1px;
    background-color: #33333333;
}

.rdp-cell button:hover:not([disabled]) {
    background-color: #411EA6 !important;
}

.rdp-nav button:hover:not([disabled]) {
    background-color: #411EA6 !important;
}

.rotate-clockwise {
    animation: rotateClockWise 10s linear infinite;
}

@keyframes rotateClockWise {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.rotate-anticlockwise {
    animation: rotateAntiClockWise 10s linear infinite;
}

@keyframes rotateAntiClockWise {
    0% {
        transform: translate(-50%, -50%) rotate(360deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

.pulse {
    transition: all 8s linear;
    animation: pulsing 8s linear infinite;
}

@keyframes pulsing {
    0% {
        transform: scale(1);
        background-color: #5675D7;
        border: 20px solid #3D559E;
    }

    17% {
        transform: scale(0.9);
        background-color: #4837AC;
        border: 20px solid #302476;
    }

    34% {
        transform: scale(0.8);
        background-color: #B338FF;
        border: 20px solid #64228C;
    }

    50% {
        transform: scale(0.7);
        background-color: #36A848;
        border: 20px solid #236D3C;
    }

    67% {
        transform: scale(0.8);
        background-color: #B338FF;
        border: 20px solid #64228C;
    }

    84% {
        transform: scale(0.9);
        background-color: #4837AC;
        border: 20px solid #302476;
    }

    100% {
        transform: scale(1);
        background-color: #5675D7;
        border: 20px solid #3D559E;
    }
}

.leftPetal {
    transition: all 8s ease-in-out;
    animation: leftPetal 8s ease-in-out infinite;
}

@keyframes leftPetal {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    50% {
        transform: translate(50px, -15px) rotate(-35deg);
    }

    100% {
        transform:  translate(0px, 0px) rotate(0deg);
    }
}

.rightPetal {
    transition: all 8s ease-in-out;
    animation: rightPetal 8s ease-in-out infinite;
    /* animation-delay: 3s; */
}

@keyframes rightPetal {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    50% {
        transform: translate(-50px, -15px) rotate(35deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@layer components {
    .landing section {
        @apply p-6 sm:p-10 max-w-[90rem] mx-auto
    }

    .landing h1 {
        @apply text-4xl sm:text-6xl xl:text-7xl font-semibold
    }

    .landing p {
        @apply text-gray-400 font-light text-xl sm:text-3xl xl:text-4xl mt-3 sm:mt-5
    }
}